import _auth from "./auth";
import _search from "./search";
import _list from "./list";
var exports = {};
var anime = {};

_auth(anime);

_search(anime);

_list(anime);

exports = anime;
export default exports;